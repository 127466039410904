import React from "react";
// import { Box, CssBaseline } from "@mui/material";
// import ProfileNav from "../Componets/ProfileNav";
import ProfileAccountDetails from "../Componets/ProfileAccountDetails";

import { AccProvider } from "../Context/OtherContext";
import PersonalInfo from "../Componets/PersonalInfo";
import ContactInfo from "../Componets/ContactInfo";
import Changepassword from "../Componets/Changepassword";
import MainNav from "../Componets/MainNav";
// import Logout from "../Componets/Logout";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import BankAccInfo from "../Componets/BankAccInfo";
import PaypalInfo from "../Componets/PaypalInfo";
import IdentityInfo from "../Componets/IdentityInfo";

// import { useMediaQuery } from "@mui/material";

function Profile() {
  // const mobile = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    if(!window.localStorage.getItem("access_token") || window.localStorage.getItem("access_token") === ""){
      window.location.href = "https://ft9ja.com/login"
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>Profile - FT9ja</title>
      </Helmet>
      <AccProvider>
        <header>
          <MainNav />
        </header>
        <ProfileAccountDetails />
        <br />
        <PersonalInfo />
        <br />
        <ContactInfo />
        <br />
        <BankAccInfo/>
        <br />
        <PaypalInfo/>
        <br />
        <IdentityInfo/>
        <br/>
        <Changepassword />
        <br />
        {/* <Logout /> */}
      </AccProvider>
    </>
  );
}

export default Profile;
