/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "../../axios";
import {
  Button,
  Modal,
  Typography,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import Bank from "../../Assets/Bank.png";
import AddCircle from "../../Assets/add-circle.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Countdown from "react-countdown";

// var fs = require("fs");
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 700 ? 280 : 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const mainContainer = {
  justifyContent: "center",
  alignItems: "center",
};

const content = {
  title: "Bank Transfer",
  body: "Please make a direct bank transfer to the account number below and upload your payment receipt.",
};

const informationFields = [
  { id: "acc", label: "Account Number" },
  { id: "acc_name", label: "Account Name" },
  { id: "bank", label: "Bank Name" },
];

const accNumber = localStorage.getItem("account_number");
const bankName = localStorage.getItem("bank_name");
const emailAddress = localStorage.getItem("account_name");

const getInformation = (id) => {
  if (id === "acc") return "3075036936";
  if (id === "bank") return "Enovation Concept";
  if (id === "acc_name") return "First Bank";
};

const BankTransferModal = ({
  open,
  handleClose,
  amount,
  dCode,
  userAccountId,
  size,
  broker,
  payout,
  type,
  changeLoading,
}) => {
  const [copied, setCopied] = useState(false);
  const [containsName, setContainsName] = useState(false);
  console.log(type);

  const makeAccount = () => {
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    // console.log(size, broker, payout);
    showUploadSuccess("File uploaded successfully");
    // setLoading(true);
    // setShowPaymentOptions(true);
    let data =
      dCode === ""
        ? {
            acc_size: size,
            broker: broker,
            payout: payout,
            typeofaccount: type,
            device_type: deviceType,
          }
        : {
            acc_size: size,
            broker: broker,
            payout: payout,
            typeofaccount: type,
            discount_code: dCode,
            device_type: deviceType,
          };
    axios.post("/getnewacc/", data).then((res) => {
      console.log(res);
      // showUploadSuccess(res.data.message);
      window.location.href = "https://dashboard.ft9ja.com/payment-successful";
      // setIsUploading(false);
      // onReceiptUploaded();
      // setLoading(false);
      // setShowPaymentOptions(true);
    });
  };

  useEffect(() => {
    axios
      .post("temp-purchase/", { amount })
      .then((res) => {
        console.log(res);
      })
      .then((err) => {
        console.log(err);
      });
  }, [amount]);

  const handleCopyClick = (id) => {
    navigator.clipboard
      .writeText(getInformation(id))
      .then(() => {
        setCopied(true);
      })
      .catch(() => {
        alert("something went wrong");
      });
  };

  const copyStatus = (id, copied) => {
    if (id === "acc" && copied === "acc") return "Copied";
    if (id === "bank" && copied === "bank") return "Copied";
    if (id === "email" && copied === "email") return "Copied";

    return "Copy";
  };

  // --Toast messages--//
  const showUploadSuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
    });
  };

  const showUploadFailed = (message) => {
    toast.error(message, {
      position: "bottom-center",
    });
  };

  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  function handleFileUpload(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    let formdata = new FormData();
    formdata.append("image", file);
    formdata.append("user_account", userAccountId);
    formdata.append("discount_code", dCode);
    formdata.append("device_type", deviceType);

    if (file) {
      // Do something with the uploaded file, such as sending it to a server using AJAX or fetch
      console.log("File uploaded:", file);
      setIsUploading(true);
      //--Upload file to server--//
      axios
        .post("receipts/", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res);
          setIsUploading(true);

          if (res.data.message === "File uploaded successfully") {
            makeAccount();
            changeLoading();
          } else {
            showUploadFailed("An error occurred while uploading the file.");
          }
        })
        .catch((error) => {
          console.log(error);
          showUploadFailed("An error occurred while uploading the file.");
        });

      setFile(null); // Clear the file input field
    }
  }

  const [checking, setChecking] = useState(false);
  const [data, setData] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [comment, setComment] = useState();

  function CheckPayment() {
    setChecking(true);
    axios
      .get("check-dva-transfer/")
      .then((res) => {
        console.log(res);
        if (res.data.status === "" && res.data.admin_status === "") {
          // Data is null or empty string, continue polling
          console.log("Data is empty or null, will continue polling...");
        } else {
          // Valid data received, stop polling
          setData(res.data);
          if (
            res.data.status === "success" &&
            res.data.admin_status === "Confirmed"
          ) {
            makeAccount();
          } else if (res.data.comment.includes("We received a payment of")) {
            let str = res.data.comment;

            // Replace "trader" with "you"
            str = str.replace("trader", "you");

            setComment(str);
            setChecking(false);
            makeAccount();
          } else {
            setComment(res.data.comment);
            setChecking(false);
            makeAccount();
          }
          axios
            .post("check-dva-transfer/", { id: res.data.id })
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            });
          console.log("Received data:", res.data);
        }
        // setChecking(false);
      })
      .catch((err) => {
        console.log(err);
        setChecking(false);
      });
  }

  useEffect(() => {
    let intervalId;

    if (checking && data === null && elapsedTime < 1800) {
      // Polling every 10 seconds if data is null or empty string
      intervalId = setInterval(() => {
        CheckPayment();
        setElapsedTime((prevTime) => prevTime + 10);
      }, 10000);
    }

    return () => {
      // Clear interval when component unmounts or valid data is received
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checking, data, elapsedTime]);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <ToastContainer />
      <Modal
        open={open}
        onClose={handleClose}
        amount={amount}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={mainContainer}>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mb: 5,
              }}
            >
              <img src={Bank} alt={""} />
              <Typography
                id="modal-modal-title"
                sx={{ textAlign: "center" }}
                variant="h6"
                component="h2"
              >
                <Box sx={{ marginLeft: 1 }}>{content.title}</Box>
              </Typography>
            </Box>
            <Box>
              <Typography
                id="modal-modal-description"
                sx={{ textAlign: "center" }}
              >
                {content.body}
              </Typography>
              <Box sx={{ my: 2 }}>
                <Typography
                  sx={{
                    bgcolor: "rgba(247, 126, 39, 0.05)",
                    p: 2,
                    textAlign: "center",
                  }}
                >
                  Amount to pay : {amount} naira
                </Typography>
              </Box>

              {informationFields.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid lightgray",
                    padding: "3px 0",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="subtitle1" sx={{}}>
                      <Box fontWeight="fontWeightBold">{item.label}</Box>
                    </Typography>
                    <Typography variant="subtitle1" sx={{}}>
                      <Box fontSize={14}>{getInformation(item.id)}</Box>
                    </Typography>
                  </Box>
                  <Button
                    onClick={() => handleCopyClick(item.id)}
                    sx={{
                      border: "1px solid lightgray",
                      padding: 1,
                      borderRadius: 2,
                    }}
                  >
                    {copyStatus(item.id, copied)}
                  </Button>
                </Box>
              ))}
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  checked={containsName}
                  type="checkbox"
                  style={{ accentColor: "#359602" }}
                  onClick={() => setContainsName(!containsName)}
                />
                <p>
                  I confirm that the receipt i am uploading contains my name
                </p>
              </div>

              {containsName && (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 3,
                  }}
                >
                  {!file ? (
                    <>
                      <label htmlFor="file-upload">
                        <Button
                          sx={{
                            background: "#AD5D23",
                            display: "flex",
                            flexDirection: "row",
                          }}
                          component="span"
                        >
                          <Typography
                            sx={{ color: "white", textAlign: "center" }}
                          >
                            <Box fontSize={16}>
                              {!isUploading ? "Upload Receipt" : "Uploading..."}
                            </Box>
                          </Typography>
                          {!isUploading && (
                            <img
                              src={AddCircle}
                              alt="icon"
                              style={{ height: 20, marginLeft: 5 }}
                            />
                          )}
                        </Button>
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleFileUpload}
                        disabled={isUploading}
                      />
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href={URL.createObjectURL(file)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ "& a:hover": { textDecoration: "none" } }}
                        >
                          {file.name}
                        </Typography>
                      </a>
                      <Button type="submit" disabled={!file}>
                        Submit
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {!checking && !comment && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ marginTop: "16px", marginBottom: "4px" }}>
                      This account is for transactions only, and it expires in:
                    </p>
                    <Countdown
                      onComplete={() => {
                        window.location.reload();
                      }}
                      date={Date.now() + 1800000}
                      renderer={({ hours, minutes, seconds, completed }) => {
                        // if (completed) {
                        //   // Render a completed state
                        //   return <Completionist />;
                        // } else {
                        //   // Render a countdown
                        // }
                        return (
                          <span
                            style={{
                              fontWeight: 500,
                              marginTop: "16px",
                              fontSize: "1.125rem",
                            }}
                          >
                            <span
                              style={{
                                border: "1px solid black",
                                borderRadius: "8px",
                                padding: "8px",
                              }}
                            >
                              {minutes}
                            </span>
                            <span
                              style={{
                                marginRight: "5px",
                                marginLeft: "5px",
                                fontWeight: 900,
                              }}
                            >
                              :
                            </span>
                            <span
                              style={{
                                border: "1px solid black",
                                borderRadius: "8px",
                                padding: "8px",
                              }}
                            >
                              {seconds}
                            </span>
                          </span>
                        );
                      }}
                    />
                  </div>
                )}
                {!comment && <button
                  onClick={() => {
                    console.log("clicked");
                    setElapsedTime(0);
                    CheckPayment();
                  }}
                  disabled={checking}
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#359602",
                    border: "1px solid #359602",
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    borderRadius: "8px",
                    color: "white",
                    paddingTop: "6px",
                    paddingBottom: "6px",
                    paddingRight: "12px",
                    paddingLeft: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    cursor: "pointer",
                  }}
                >
                  {checking ? "Checking Payment..." : "I have sent the money"}{" "}
                  {checking && (
                    <CircularProgress
                      size={12}
                      sx={{
                        zIndex: 1,
                        color: "white",
                      }}
                    />
                  )}
                </button>}
                {checking && !comment && (
                  <p style={{ textAlign: "center", color: "#359602" }}>
                    Pls do not refresh the page while we check to confirm your
                    payment. To cancel this process,{" "}
                    <span
                      onClick={() => {
                        window.location.reload();
                      }}
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      click here
                    </span>
                  </p>
                )}

                {comment && (
                  <p style={{ textAlign: "center", color: "red" }}>{`${comment}. Please contact us at traders@ft9ja.com`}</p>
                )}
              </div> */}
            </Box>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        message="Copied to clibboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={20000}
        onClose={() => setCopied(false)}
        open={copied}
      />
    </div>
  );
};
//push new
export default BankTransferModal;
