import React from "react";
import { Link } from "react-router-dom";
import axios from "../axios";

function PaymentSuccessful() {
  const [access, setAccess] = React.useState(false);
  React.useEffect(() => {
    axios
      .get("/getaccdetails/")
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          setAccess(true);
        }
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        // navigate("/");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex items-center justify-center">
      <div className="shadow-lg mt-20 lg:w-[70%] p-10">
        <h1>
          {localStorage.getItem("hundred")
            ? "Congratulations"
            : "Payment Successful"}
          !!!
        </h1>
        {!localStorage.getItem("hundred") ? (
          <p>Thanks for uploading the proof of payment</p>
        ) : (
          <p>Your 100% discount has been processed</p>
        )}
        {access && (
          <p>
            Kindly note that your account is being processed by our accounts
            team. Once confirmed, your logins will be sent via email within
            24hrs.
          </p>
        )}
        {!access && (
          <p>
            Please note that your account is currently being processed by our
            accounts team, <br /> and you do not have access to your dashboard
            at this time. <br /> <br /> Once confirmed, your login details will
            be sent via email within 24 hours, <br /> and you will then be
            granted access to your dashboard.
          </p>
        )}
        <p>Thanks for choosing FT9ja.</p>
        {access && (
          <Link to="/dashboards">
            <button className="bg-[#359602] text-white text-lg border-none rounded-[10px] p-3 cursor-pointer">
              Go to Dashboard
            </button>
          </Link>
        )}
        {!access && (
          <Link to="/">
            <button className="bg-[#359602] text-white text-lg border-none rounded-[10px] p-3 cursor-pointer">
              Go to Home
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}

export default PaymentSuccessful;
